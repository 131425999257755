import { ENV_CONSTANTS } from "@/common/constants/env.const";
import routes from "@/common/constants/routes";
import axios from "axios";
import {
  INewProjectsResponse,
  IProjectsResponse,
  IRequestNewProjects,
  IRequestProjects,
} from "@/api/types/workspace.types";

export default class WorkspaceAPIs {
  private static baseUrl = routes.WORKSPACES;

  public static async RequestWorkspaces() {
    return await axios.get(`${ENV_CONSTANTS.API_BASE_URL_V2}${this.baseUrl}`);
  }

  public static async RequestProjects({
    workspaceId,
    projectId,
  }: IRequestProjects) {
    return await axios.get<IProjectsResponse>(
      `${ENV_CONSTANTS.API_BASE_URL_V2}/workspaces/${workspaceId}/folders/${projectId}`,
    );
  }

  public static async RequestNewProjects({
    workspaceId,
    projectId,
    name,
  }: IRequestNewProjects) {
    return await axios.post<INewProjectsResponse>(
      `${ENV_CONSTANTS.API_BASE_URL_V2}/workspaces/${workspaceId}/folders/${projectId}`,
      {
        name,
      },
    );
  }
}
